import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Cta from "../components/cta";
import Hero from "../components/hero";

import { Link, graphql } from "gatsby";
import PropTypes from "prop-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

function HelpCenter({ data }) {
    let d = data.contentfulHelpCenter;

    return (
        <Layout>
            <SEO title={d.title} description={d.subtitle} />

            <Hero
                props={{
                    padding: `10`,
                    title: d.title,
                    desc: d.subtitle,
                }}
            />

            <section className="bg-gray-50 dark:bg-gray-800 paragraph">
                <div className="w-full max-w-5xl mx-auto px-4 py-8 md:px-8 md:py-16 ">
                    <div className="flex flex-wrap">
                        {data.allContentfulHelpCenterItem.nodes.map((item) => (
                            <div
                                className="w-full md:w-1/2 px-3 mb-6"
                                key={item.title}
                            >
                                <div className="bg-white dark:bg-gray-700 rounded-xl p-6 mb-4 mx-0 h-full shadow-md">
                                    <img
                                        src={`../icons/myicons/${item.icon}`}
                                        alt={`${item.title.toLowerCase()} icon`}
                                        className="w-12 h-12 mb-2"
                                    />
                                    <h2 className="text-xl font-bold font-sans mb-4">
                                        {item.title}
                                    </h2>
                                    <div className="text-gray-600 dark:text-gray-400">
                                        {renderRichText(item.content)}
                                    </div>
                                    <Link
                                        className="text-primary dark:text-gray-400"
                                        to={item.ctaUrl}
                                    >
                                        {item.ctaText}
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <Cta />
        </Layout>
    );
}

HelpCenter.propTypes = {
    data: PropTypes.object,
};

export const query = graphql`
    {
        contentfulHelpCenter {
            title
            subtitle
        }
        allContentfulHelpCenterItem(sort: { fields: priority, order: ASC }) {
            nodes {
                title
                content {
                    raw
                }
                icon
                ctaUrl
                ctaText
                priority
            }
        }
    }
`;

export default HelpCenter;
